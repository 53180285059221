/* You can add global styles to this file, and also import other style files */
@import "../../node_modules/primeicons/primeicons.css";

@font-face {
  font-family: "Lab Grotesque", sans-serif;
  src: url('../assets/fonts/LabGrotesque-Regular.ttf');
}

:root {
  font-family: "Lab Grotesque", sans-serif;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

router-outlet+* {
  display: block;
  animation: fade 0.5s;
}

// angular-editor
.default-mediator-style .angular-editor .angular-editor-wrapper .angular-editor-textarea {
  background-color: #ffffff !important;
  border-color: transparent !important;
  border-radius: 10px !important;
}

.default-mediator-style .angular-editor .angular-editor-wrapper .angular-editor-placeholder {
  opacity: 1 !important;
  padding: .5rem .8rem 1rem .8rem !important;
}

// scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  border: solid 3px transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
  &:hover {
    background-color: #848484;
  }
}

@layer primeng {
  .p-component {
    font-family: 'Lab Grotesque', sans-serif;
  }

  // inputText
  .p-inputtext {
    font-family: "Lab Grotesque", sans-serif;
    border-color: transparent;
    border-radius: 10px;
  }

  .p-inputtext.ng-touched.ng-invalid {
    border-color: #f44336;
  }

  .p-input-icon-left > i {
    top: 45%;
  }

  // p-dropDown
  .p-dropdown {
    border-color: transparent;
    border-radius: 10px;
  }

  p-dropdown.ng-touched.ng-invalid > .p-dropdown {
    border-color: #f44336;
  }

  // p-multiselect
  .p-multiselect {
    border-color: transparent;
    border-radius: 10px;
  }

  // p-button
  .p-button {
    background: #771DAD;
    border-radius: 12px;
    font-size: 14px;
  }

  .p-button.p-button-link {
    color: #771DAD;
  }

  .p-button-label {
    font-weight: 400;
  }

  // p-dialog
  .p-dialog {
    border-radius: 32px;
  }

  .p-dialog .p-dialog-header {
    background-color: #771DAD;
    color: #fff;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }

  .p-dialog .p-dialog-footer {
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #fff;
  }
}
