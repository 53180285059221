/* Предполагаю сюда добавлять свои mixin-ы для адаптивной верстки на primeflex */

@import 'primeflex/primeflex.scss';

@media screen and (min-width: 768px) {
  .md\:max-w-75vw {
    max-width: 75vw !important;
  }

  .md\:max-w-50vw {
    max-width: 50vw !important;
  }
}
